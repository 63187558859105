<template>
    <div style="display: flex; align-items: center; flex-direction: column">
        <div style="display: flex;flex-direction: column;margin-top: 2vh;align-items: center;">
            <div style="width: 100vw;display: flex;justify-content: end;justify-content: flex-end;margin-right: 5vw;">
                <el-dropdown size="medium" placement="bottom" @command="handleClickLocale">
                    <i class="iconfont icon-locale"
                        style="cursor: pointer; font-size: 25px; color: rgba(0, 66, 166, 1)"></i>
                    <el-dropdown-menu slot="dropdown" class="menu-item">
                        <el-dropdown-item command="zh">简体中文</el-dropdown-item>
                        <el-dropdown-item command="en" divided>English </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <img src="../../assets/img/phoneLoginLogo.png" alt=""
                style="width: 80vw;margin-top: 6vh;margin-bottom: 10vh;">
        </div>
        <div class="subtitle">{{ $t("addLogin.resetPsd.title") }}</div>
        <div class="r2 router">
            <span>{{ $t("addLogin.forgetPsd.title2") }}&nbsp;</span>
            <router-link to="/phoneloginp" class="clear" style="display: flex; align-items: center">
                <p>{{ $t("addLogin.loginEmail.login") }}</p>
            </router-link>
        </div>
        <div class="main">
            <div class="inputbox">
                <div class="box">
                    <div class="input">
                        <input v-model="mail" type="text" class="all email"
                            :placeholder="$t('addLogin.forgetPsd.place1')" /><i
                            class="iconfont icon-email frontIcon"></i>
                    </div>
                    <div class="input">
                        <input id="code" v-model="code" :autoComplete="false" type="text" class="num"
                            :placeholder="$t('addLogin.forgetPsd.place3')" />
                        <el-button type="primary" v-if="!issend" class="yzm" @click="sendCode">
                            <p>{{ $t("addLogin.forgetPsd.getCode") }}</p>
                        </el-button>
                        <el-button type="primary" v-else class="yzm1">
                            <p id="yzm1">{{ $t("addLogin.forgetPsd.message6") }}</p>
                        </el-button>
                    </div>
                    <div class="input">
                        <input id="password" v-model="password" type="password" class="all email"
                            :placeholder="$t('addLogin.forgetPsd.place2')" />
                        <i class="iconfont icon-locker frontIcon"></i>
                        <i v-if="eye" class="iconfont icon-pwo backIcon" @click="changePwVisible"></i>
                        <i v-if="!eye" class="iconfont icon-pwc backIcon" @click="changePwVisible"></i>
                    </div>

                    <!-- <div style="height:50px"></div> -->
                    <div class="lobtn">
                        <el-button class="login_goto" type="primary" :loading="btnLoading" @click="handleLogin">{{
            $t("addLogin.resetPsd.btn") }}</el-button>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import { request_verify_code, forget_password } from "@/network/login/index.js";
import Cookies from "js-cookie";
export default {
    data() {
        return {
            btnLoading: false,
            eye: false,
            vercode: "",
            code: "",
            password: "",
            mail: "",
            name: "",
            phone: "",
            id: "",
            issend: false,
            timeo: 180,
        };
    },
    methods: {
        handleClickLocale(command) {
            localStorage.setItem("lang", command);
            window.location.reload();
        },
        changePwVisible() {
            if (document.getElementById("password").type == "password") {
                document.getElementById("password").type = "text";
                this.eye = true;
            } else {
                document.getElementById("password").type = "password";
                this.eye = false;
            }
        },
        handleLogin() {
            if (!this.check_email()) {
                return false;
            }
            if (this.password.length < 1) {
                this.$message.warning(this.$t("addLogin.forgetPsd.place2"));
                return false;
            }
            if (this.code.length < 1) {
                this.$message.warning(this.$t("addLogin.forgetPsd.place3"));
                return false;
            }
            this.btnLoading = true;
            forget_password(this.mail, this.password, this.code)
                .then((res) => {
                    //PERF(ZHOU) 或许可以注册后直接登录
                    this.$message.success(this.$t("addLogin.resetPsd.message.p1"));
                    this.$router.push("/login/login_password");
                })
                .catch((err) => {
                    this.btnLoading = false;
                    console.log(err);
                });
        },

        sendCode() {
            if (this.check_email()) {
                this.vercode = Math.random().toFixed(6).slice(-6);
                Cookies.set("vercode", this.vercode, {
                    expires: 60000 / 864e5,
                });
                request_verify_code(this.mail, "RESET_PASSWORD").then((res) => {
                    this.$message({
                        type: "success",
                        message: this.$t("addLogin.forgetPsd.message7"),
                        duration: "5000",
                    });
                    this.issend = true;
                    let timeo = 180;
                    let timeStop = setInterval(() => {
                        timeo--;
                        if (timeo > 0) {
                            document.getElementById("yzm1").innerText =
                                this.$t("addLogin.forgetPsd.message8") +
                                timeo +
                                this.$t("addLogin.forgetPsd.message9");
                        } else {
                            timeo = 180;
                            this.issend = false;
                            document.getElementById("yzm1").innerText = this.$t(
                                "addLogin.forgetPsd.getCode"
                            );
                            clearInterval(timeStop);
                        }
                    }, 1000);
                });
            }
        },
        check_email() {
            let emailRegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (!emailRegExp.test(this.mail)) {
                this.$message.warning(this.$t("addLogin.loginr.place1"));
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.subtitle {
    width: 88vw;
    font-size: 26px;
    font-weight: 700;
    text-align: start;
    color: rgba(56, 56, 56, 1);
}

.router {
    height: 100%;
    width: 88vw;
    text-align: start;
    font-size: 14px;
    display: flex;
    margin-top: 0.5vh;

    span {
        color: rgba(137, 138, 140, 1);
    }

    p {
        margin: 0;
        color: rgba(47, 100, 181, 1);
    }
}

.num {
    font-size: 16px;
    padding-left: 5vw;
    width: 50vw;
    height: 5.5vh;
    border: 1px solid rgba(195, 195, 195, 1);
    border-radius: 6px;
    outline: none;
}

.email {
    font-size: 16px;
    padding-left: 8vw;
    padding-right: 8vw;
    width: 88vw;
    height: 5.5vh;
    border: 1px solid rgba(195, 195, 195, 1);
    border-radius: 6px;
    outline: none;
}

.clear {
    color: inherit;
    user-select: none;
}

.yzm {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 5.5vh;
    width: 35vw;
    border: 1px solid rgba(48, 100, 143, 1);

    p {
        font-size: 14px;
        margin: 0;
    }
}

.yzm1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 5.5vh;
    width: 35vw;
    border: 1px solid rgba(48, 100, 143, 1);
    p {
        letter-spacing: 2px;
        font-size: 14px;
        margin: 0;
    }
}

.main {
    width: 100%;
    display: grid;
    grid-template-columns: 100% 60%;
    // border: 2px solid white;
    border-radius: 20px;

    .inputbox {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;

        .box {
            margin-top: 3vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 2.5vh;
        }

        .lobtn {
            width: 88vw;
            display: flex;
            flex-direction: column;
            align-items: center;

            .login_goto {
                font-size: 16px;
                font-weight: 700;
                word-spacing: 10px;
                border-radius: 10px;
                width: 100%;
                margin: 0px 10px;
                padding-top: 1.5vh;
                padding-bottom: 1.5vh;
            }
        }

        .input {
            // margin-top: -50px;
            margin-bottom: 3vh;
            width: 88vw;
            position: relative;
            padding-bottom: 0.5vh;
            display: flex;
            justify-content: space-between;
        }

        .all {
            font-size: 16px;
        }

        input::-webkit-input-placeholder {
            padding-left: 3vw;
            color: rgba(144, 147, 153, 1);
            font-size: 16px;
            letter-spacing: 2px;
        }
    }

    .frontIcon {
        position: absolute;
        cursor: pointer;
        color: rgba(195, 195, 195, 1);
        font-size: 16px;
        margin: 2.75vh auto;
        margin-left: 11px;
        transform: translateY(-8px);
    }

    .backIcon {
        position: absolute;
        cursor: pointer;
        right: 19px;
        color: rgba(195, 195, 195, 1);
        font-size: 16px;
        margin: 2.75vh auto;
        transform: translateY(-8px);
    }
}
</style>